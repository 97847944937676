const listaControlada = {
    tiposImovel: {
        '1': '1 - Casa',
        '2': '2 - Apartamento',
        '3': '3 - Loja',
        '4': '4 - Sala/conjunto',
        '5': '5 - Terreno/fração',
        '6': '6 - Galpão',
        '7': '7 - Prédio comercial',
        '8': '8 - Prédio residencial',
        '9': '9 - Fazenda/sítio/chácara',
        '10': '10 - Garagem/Vaga',
        '11': '11 - Box/Depósito',
        '12': '12 - Públicos',
        '13': '13 - Outros'
    },
    tiposRegistro: {
        '1': '1 - Matrícula',
        '2': '2 - Matrícula MÃE',
        '3': '3 - Livro 3-reg-aux',
        '4': '4 - Transcrição'
    },
    tiposLogradouro: {
        '1': '1 - Acampamento',
        '2': '2 - Acesso',
        '3': '3 - Açude',
        '4': '4 - Adro',
        '5': '5 - Aeroporto',
        '6': '6 - Afluente',
        '7': '7 - Aglomerado',
        '8': '8 - Agrovila',
        '9': '9 - Alagado',
        '10': '10 - Alameda',
        '11': '11 - Aldeia',
        '12': '12 - Aleia',
        '13': '13 - Alto',
        '14': '14 - Anel',
        '15': '15 - Antiga',
        '16': '16 - Antigo',
        '17': '17 - Área',
        '18': '18 - Areal',
        '19': '19 - Arraial',
        '20': '20 - Arroio',
        '21': '21 - Artéria',
        '22': '22 - Assentamento',
        '23': '23 - Atalho',
        '24': '24 - Aterro',
        '25': '25 - Autódromo',
        '26': '26 - Avenida',
        '27': '27 - Baia',
        '28': '28 - Bairro',
        '29': '29 - Baixa',
        '30': '30 - Baixada',
        '31': '31 - Baixadão',
        '32': '32 - Baixão',
        '33': '33 - Baixo',
        '34': '34 - Balão',
        '35': '35 - Balneário',
        '36': '36 - Barra',
        '37': '37 - Barragem',
        '38': '38 - Barranca',
        '39': '39 - Barranco',
        '40': '40 - Barreiro',
        '41': '41 - Barro',
        '42': '42 - Beco',
        '43': '43 - Beira',
        '44': '44 - Beirada',
        '45': '45 - Belvedere',
        '46': '46 - Bloco',
        '47': '47 - Bocaina',
        '48': '48 - Boqueirão',
        '49': '49 - Bosque',
        '50': '50 - Boulevard',
        '51': '51 - Brejo',
        '52': '52 - Buraco',
        '53': '53 - Cabeceira',
        '54': '54 - Cachoeira',
        '55': '55 - Cachoeirinha',
        '56': '56 - Cais',
        '57': '57 - Calcada',
        '58': '58 - Calçadão',
        '59': '59 - Caminho',
        '60': '60 - Campo',
        '61': '61 - Canal',
        '62': '62 - Canteiro',
        '63': '63 - Capão',
        '64': '64 - Capoeira',
        '65': '65 - Cartódromo',
        '66': '66 - Central',
        '67': '67 - Centro',
        '68': '68 - Cerca',
        '69': '69 - Cerrado',
        '70': '70 - Cerro',
        '71': '71 - Chácara',
        '72': '72 - Chapada',
        '73': '73 - Chapadão',
        '74': '74 - Charco',
        '75': '75 - Cidade',
        '76': '76 - Circular',
        '77': '77 - Cohab',
        '78': '78 - Colina',
        '79': '79 - Colônia',
        '80': '80 - Comunidade',
        '81': '81 - Condomínio',
        '82': '82 - Conjunto',
        '83': '83 - Continuação',
        '84': '84 - Contorno',
        '85': '85 - Corredor',
        '86': '86 - Córrego',
        '87': '87 - Costa',
        '88': '88 - Coxilha',
        '89': '89 - Cruzamento',
        '90': '90 - Descida',
        '91': '91 - Desvio',
        '92': '92 - Dique',
        '93': '93 - Distrito',
        '94': '94 - Divisa',
        '95': '95 - Divisão',
        '96': '96 - Divisor',
        '97': '97 - Edifício',
        '98': '98 - Eixo',
        '99': '99 - Elevado',
        '100': '100 - Encosta',
        '101': '101 - Engenho',
        '102': '102 - Enseada',
        '103': '103 - Entrada',
        '104': '104 - Entreposto',
        '105': '105 - Entroncamento',
        '106': '106 - Escada',
        '107': '107 - Escadão',
        '108': '108 - Escadaria',
        '109': '109 - Escadinha',
        '110': '110 - Espigão',
        '111': '111 - Esplanada',
        '112': '112 - Esquina',
        '113': '113 - Estação',
        '114': '114 - Estacionamento',
        '115': '115 - Estádio',
        '116': '116 - Estância',
        '117': '117 - Estrada',
        '118': '118 - Extensão',
        '119': '119 - Faixa',
        '120': '120 - Favela',
        '121': '121 - Fazenda',
        '122': '122 - Feira',
        '123': '123 - Ferrovia',
        '124': '124 - Final',
        '125': '125 - Floresta',
        '126': '126 - Folha',
        '127': '127 - Fonte',
        '128': '128 - Fortaleza',
        '129': '129 - Freguesia',
        '130': '130 - Fundos',
        '131': '131 - Furo',
        '132': '132 - Galeria',
        '133': '133 - Gameleira',
        '134': '134 - Garimpo',
        '135': '135 - Gleba',
        '136': '136 - Granja',
        '137': '137 - Grota',
        '138': '138 - Habitacional',
        '139': '139 - Haras',
        '140': '140 - Hipódromo',
        '141': '141 - Horto',
        '142': '142 - Igarapé',
        '143': '143 - Ilha',
        '144': '144 - Inaplicável',
        '145': '145 - Invasão',
        '146': '146 - Jardim',
        '147': '147 - Jardinete',
        '148': '148 - Ladeira',
        '149': '149 - Lado',
        '150': '150 - Lago',
        '151': '151 - Lagoa',
        '152': '152 - Lagoinha',
        '153': '153 - Largo',
        '154': '154 - Lateral',
        '155': '155 - Leito',
        '156': '156 - Ligação',
        '157': '157 - Limeira',
        '158': '158 - Limite',
        '159': '159 - Limites',
        '160': '160 - Linha',
        '161': '161 - Lote',
        '162': '162 - Loteamento',
        '163': '163 - Lugarejo',
        '164': '164 - Maloca',
        '165': '165 - Manancial',
        '166': '166 - Mangue',
        '167': '167 - Margem',
        '168': '168 - Margens',
        '169': '169 - Marginal',
        '170': '170 - Marina',
        '171': '171 - Mata',
        '172': '172 - Mato',
        '173': '173 - Módulo',
        '174': '174 - Monte',
        '175': '175 - Morro',
        '176': '176 - Muro',
        '177': '177 - Não Especificado',
        '178': '178 - Núcleo',
        '179': '179 - Oca',
        '180': '180 - Oleoduto',
        '181': '181 - Olho',
        '182': '182 - Olhos',
        '183': '183 - Orla',
        '184': '184 - Outros',
        '185': '185 - Paco',
        '186': '186 - Palafita',
        '187': '187 - Pântano',
        '188': '188 - Parada',
        '189': '189 - Paradouro',
        '190': '190 - Paralela',
        '191': '191 - Parque',
        '192': '192 - Particular',
        '193': '193 - Passagem',
        '194': '194 - Passarela',
        '195': '195 - Passeio',
        '196': '196 - Passo',
        '197': '197 - Pasto',
        '198': '198 - Pátio',
        '199': '199 - Pavilhão',
        '200': '200 - Pedra',
        '201': '201 - Pedras',
        '202': '202 - Pedreira',
        '203': '203 - Penhasco',
        '204': '204 - Perimetral',
        '205': '205 - Perímetro',
        '206': '206 - Perto',
        '207': '207 - Planalto',
        '208': '208 - Plataforma',
        '209': '209 - Ponta',
        '210': '210 - Ponte',
        '211': '211 - Ponto',
        '212': '212 - Porto',
        '213': '213 - Posto',
        '214': '214 - Povoado',
        '215': '215 - Praça',
        '216': '216 - Praia',
        '217': '217 - Projeção',
        '218': '218 - Projetada',
        '219': '219 - Projeto',
        '220': '220 - Prolongamento',
        '221': '221 - Propriedade',
        '222': '222 - Próximo',
        '223': '223 - Quadra',
        '224': '224 - Quarteirão',
        '225': '225 - Quilombo',
        '226': '226 - Quilometro',
        '227': '227 - Quinta',
        '228': '228 - Quintas',
        '229': '229 - Rachão',
        '230': '230 - Ramal',
        '231': '231 - Rampa',
        '232': '232 - Rancho',
        '233': '233 - Recanto',
        '234': '234 - Região',
        '235': '235 - Represa',
        '236': '236 - Residencial',
        '237': '237 - Reta',
        '238': '238 - Retiro',
        '239': '239 - Retorno',
        '240': '240 - Riacho',
        '241': '241 - Ribanceira',
        '242': '242 - Ribeirão',
        '243': '243 - Rincão',
        '244': '244 - Rio',
        '245': '245 - Rocha',
        '246': '246 - Rochedo',
        '247': '247 - Rodovia',
        '248': '248 - Rotatória',
        '249': '249 - Rotula',
        '250': '250 - Rua',
        '251': '251 - Ruela',
        '252': '252 - Saco',
        '253': '253 - Saída',
        '254': '254 - Sanga',
        '255': '255 - Sede',
        '256': '256 - Sem',
        '257': '257 - Seringal',
        '258': '258 - Serra',
        '259': '259 - Sertão',
        '260': '260 - Servidão',
        '261': '261 - Seta',
        '262': '262 - Setor',
        '263': '263 - Sitio',
        '264': '264 - Sopé',
        '265': '265 - Subida',
        '266': '266 - Superquadra',
        '267': '267 - Tapera',
        '268': '268 - Terminal',
        '269': '269 - Terra',
        '270': '270 - Terreno',
        '271': '271 - Terrenos',
        '272': '272 - Transversal',
        '273': '273 - Travessa',
        '274': '274 - Travessão',
        '275': '275 - Travessia',
        '276': '276 - Trecho',
        '277': '277 - Trevo',
        '278': '278 - Trilha',
        '279': '279 - Trilho',
        '280': '280 - Trilhos',
        '281': '281 - Trincheira',
        '282': '282 - Túnel',
        '283': '283 - Unidade',
        '284': '284 - Usina',
        '285': '285 - Vala',
        '286': '286 - Valão',
        '287': '287 - Vale',
        '288': '288 - Vargem',
        '289': '289 - Variante',
        '290': '290 - Várzea',
        '291': '291 - Velódromo',
        '292': '292 - Vereda',
        '293': '293 - Vertente',
        '294': '294 - Via',
        '295': '295 - Viaduto',
        '296': '296 - Vicinal',
        '297': '297 - Viela',
        '298': '298 - Vila',
        '299': '299 - Vilarejo',
        '300': '300 - Volta',
        '301': '301 - Zona',
        '302': '302 - 1a Travessa da Avenida',
        '303': '303 - 1a Travessa da Rua',
        '304': '304 - 2a Travessa da Avenida',
        '305': '305 - 2a Travessa da Rua',
        '306': '306 - 3a Travessa da Avenida',
        '307': '307 - 3a Travessa da Rua',
        '308': '308 - 4a Travessa da Avenida',
        '309': '309 - 4a Travessa da Rua',
        '310': '310 - 5a Travessa da Avenida',
        '311': '311 - 5a Travessa da Rua'
    },
    tiposVigencia: {
        '1': 'Ativo (Matrícula aberta e vigente)',
        '2': 'Encerrado pelo cartório',
        '3': 'Cancelado Administrativo',
        '4': 'Cancelado Judicial',
        '5': 'Requalificado (Prov. 13 CGJ/PA)',
        '6': 'Inexistente'
    },
    tiposLivro3: {
        '1': '1 - Cédulas',
        '2': '2 - Convenção, instituição e especificação de condomínio',
        '3': '3 - Bem de Família',
        '4': '4 - Pacto Antenupcial/Pós-Nupcial',
        '5': '5 - União Estável',
        '6': '6 - Processo administrativo decorrente da falha de numeração',
        '7': '7 - Penhor por instrumento público/particular',
        '8': '8 - Emissão de debêntures',
        '9': '9 - Alteração do regime de bens',
        '10': '10 - Tombamento',
        '11': '11 - Outros'
    },
    tiposMoedas: {
        'Real': '1 - (R$) Real',
        'Cruzeiro Real': '2 - (CR$) Cruzeiro Real',
        'Cruzeiro': '3 - (Cr$) Cruzeiro',
        'Cruzado Novo': '4 - (NCz$) Cruzado Novo',
        'Cruzado': '5 - (Cz$) Cruzado',
        'Réis': '6 - (Rs) Réis'
    },
    tiposNacionalidade: {
        'Brasileira': '1 - Brasileira',
        'Afegãne': '2 - Afegãne',
        'Alemã': '3 - Alemã',
        'Albanesa': '4 - Albanesa',
        'Angolana': '5 - Angolana',
        'Anguillana': '6 - Anguillana',
        'Antiguana': '7 - Antiguana',
        'Argelina': '8 - Argelina',
        'Argentina': '9 - Argentina',
        'Armênia': '10 - Armênia',
        'Arubana': '11 - Arubana',
        'Australiana': '12 - Australiana',
        'Austríaca': '13 - Austríaca',
        'Azerbaijano': '14 - Azerbaijano',
        'Bahamense': '15 - Bahamense',
        'Barenita': '16 - Barenita',
        'Bengali': '17 - Bengali',
        'Barbadiana': '18 - Barbadiana',
        'Bielo-russa': '19 - Bielo-russa',
        'Belga': '20 - Belga',
        'Belizenha': '21 - Belizenha',
        'Beninense': '22 - Beninense',
        'Bermudiana': '23 - Bermudiana',
        'Boliviana': '24 - Boliviana',
        'Bósnia': '25 - Bósnia',
        'Britânica': '26 - Britânica',
        'Bruneiana': '27 - Bruneiana',
        'Búlgara': '28 - Búlgara',
        'Burquinês': '29 - Burquinês',
        'Burundinesa': '30 - Burundinesa',
        'Butanesa': '31 - Butanesa',
        'Cabo-verdiana': '32 - Cabo-verdiana',
        'Camaronesa': '33 - Camaronesa',
        'Cambojana': '34 - Cambojana',
        'Canadense': '35 - Canadense',
        'Canário': '36 - Canário',
        'Cazaque': '37 - Cazaque',
        'Chilena': '38 - Chilena',
        'Chinesa': '39 - Chinesa',
        'Cipriota': '40 - Cipriota',
        'Cingapuriana': '41 - Cingapuriana',
        'Colombiana': '42 - Colombiana',
        'Comorense': '43 - Comorense',
        'Congolesa': '44 - Congolesa',
        'Costarriquenha': '45 - Costarriquenha',
        'Coreana': '46 - Coreana',
        'Croata': '47 - Croata',
        'Cubana': '48 - Cubana',
        'Curaçauense': '49 - Curaçauense',
        'Chagositano': '50 - Chagositano',
        'Dinamarquesa': '51 - Dinamarquesa',
        'Djibutiana': '52 - Djibutiana',
        'Dominiquense': '53 - Dominiquense',
        'Egípcia': '54 - Egípcia',
        'Salvadorenha': '55 - Salvadorenha',
        'Árabe': '56 - Árabe',
        'Equatoriana': '57 - Equatoriana',
        'Eritreia': '58 - Eritreia',
        'Eslovaco': '59 - Eslovaco',
        'Esloveno': '60 - Esloveno',
        'Espanhola': '61 - Espanhola',
        'Norte-americana': '62 - Norte-americana',
        'Estoniana': '63 - Estoniana',
        'Etíope': '64 - Etíope',
        'Fijiana': '65 - Fijiana',
        'Filipina': '66 - Filipina',
        'Finlandesa': '67 - Finlandesa',
        'Francesa': '68 - Francesa',
        'Gabonesa': '69 - Gabonesa',
        'Gambiana': '70 - Gambiana',
        'Ganense': '71 - Ganense',
        'Georgiana': '72 - Georgiana',
        'Gibraltariana': '73 - Gibraltariana',
        'Inglesa': '74 - Inglesa',
        'Granadina': '75 - Granadina',
        'Grega': '76 - Grega',
        'Groenlandesa': '77 - Groenlandesa',
        'Guadalupense': '78 - Guadalupense',
        'Guamês': '79 - Guamês',
        'Guatemalteca': '80 - Guatemalteca',
        'Guernesiano': '81 - Guernesiano',
        'Guianense': '82 - Guianense',
        'Franco-guianense': '83 - Franco-guianense',
        'Guinéu-equatoriano ou equatoguineana': '84 - Guinéu-equatoriano ou equatoguineana',
        'Guinéu-equatoriano': '85 - Guinéu-equatoriano',
        'Guineense': '86 - Guineense',
        'Haitiana': '87 - Haitiana',
        'Holandês': '88 - Holandês',
        'Hondurenha': '89 - Hondurenha',
        'Hong-konguiana ou chinesa': '90 - Hong-konguiana ou chinesa',
        'Húngara': '91 - Húngara',
        'Iemenita': '92 - Iemenita',
        'Da ilha Bouvet': '93 - Da ilha Bouvet',
        'Da ilha de Ascensão': '94 - Da ilha de Ascensão',
        'Da ilha de Clipperton': '95 - Da ilha de Clipperton',
        'Manês': '96 - Manês',
        'Natalense': '97 - Natalense',
        'Pitcairnense': '98 - Pitcairnense',
        'Reunionense': '99 - Reunionense',
        'Alandês': '100 - Alandês',
        'Caimanês': '101 - Caimanês',
        'Coquense': '102 - Coquense',
        'Cookense': '103 - Cookense',
        'Faroense': '104 - Faroense',
        'Das ilhas Geórgia do Sul e Sandwich do Sul': '105 - Das ilhas Geórgia do Sul e Sandwich do Sul',
        'Das ilhas Heard e McDonald': '106 - Das ilhas Heard e McDonald',
        'Malvinense': '107 - Malvinense',
        'Norte-marianense': '108 - Norte-marianense',
        'Marshallino': '109 - Marshallino',
        'Das ilhas Menores Afastadas': '110 - Das ilhas Menores Afastadas',
        'Norfolquino': '111 - Norfolquino',
        'Salomônico': '112 - Salomônico',
        'Seichelense': '113 - Seichelense',
        'Toquelauano': '114 - Toquelauano',
        'Turquês': '115 - Turquês',
        'Virginense': '116 - Virginense',
        'Indiano': '117 - Indiano',
        'Indonésia': '118 - Indonésia',
        'Iraniano': '119 - Iraniano',
        'Iraquiana': '120 - Iraquiana',
        'Irlandesa': '121 - Irlandesa',
        'Islandesa': '122 - Islandesa',
        'Israelense': '123 - Israelense',
        'Italiana': '124 - Italiana',
        'Jamaicana': '125 - Jamaicana',
        'Japonesa': '126 - Japonesa',
        'Canalina': '127 - Canalina',
        'Jordaniana': '128 - Jordaniana',
        'Kiribatiana': '129 - Kiribatiana',
        'Kosovar': '130 - Kosovar',
        'Kuwaitiano': '131 - Kuwaitiano',
        'Laosiana': '132 - Laosiana',
        'Lesota': '133 - Lesota',
        'Letão': '134 - Letão',
        'Libanesa': '135 - Libanesa',
        'Liberiana': '136 - Liberiana',
        'Líbia': '137 - Líbia',
        'Liechtensteiniense': '138 - Liechtensteiniense',
        'Lituana': '139 - Lituana',
        'Luxemburguesa': '140 - Luxemburguesa',
        'Macauense': '141 - Macauense',
        'Macedônio': '142 - Macedônio',
        'Malgaxe': '143 - Malgaxe',
        'Malaia': '144 - Malaia',
        'Malauiano': '145 - Malauiano',
        'Maldívia': '146 - Maldívia',
        'Malinesa': '147 - Malinesa',
        'Maltesa': '148 - Maltesa',
        'Marroquina': '149 - Marroquina',
        'Martiniquense': '150 - Martiniquense',
        'Mauriciana': '151 - Mauriciana',
        'Mauritana': '152 - Mauritana',
        'Maiotense': '153 - Maiotense',
        'Mexicana': '154 - Mexicana',
        'Micronésia': '155 - Micronésia',
        'Moçambicana': '156 - Moçambicana',
        'Moldavo': '157 - Moldavo',
        'Monegasca': '158 - Monegasca',
        'Mongol': '159 - Mongol',
        'Montenegra': '160 - Montenegra',
        'Montserratiano': '161 - Montserratiano',
        'Birmanês': '162 - Birmanês',
        'Namíbia': '163 - Namíbia',
        'Nauruana': '164 - Nauruana',
        'Nepalesa': '165 - Nepalesa',
        'Nicaraguense': '166 - Nicaraguense',
        'Nigerina': '167 - Nigerina',
        'Nigeriana': '168 - Nigeriana',
        'Niueana': '169 - Niueana',
        'Norueguesa': '170 - Norueguesa',
        'Caledônia': '171 - Caledônia',
        'Neozelandesa': '172 - Neozelandesa',
        'Omani': '173 - Omani',
        'Dos países Baixos Caribenhos': '174 - Dos países Baixos Caribenhos',
        'Palauense': '175 - Palauense',
        'Palestino': '176 - Palestino',
        'Zona do Canal do Panamá': '177 - Zona do Canal do Panamá',
        'Pauásia': '178 - Pauásia',
        'Paquistanesa': '179 - Paquistanesa',
        'Paraguaia': '180 - Paraguaia',
        'Peruana': '181 - Peruana',
        'Franco-polinésia': '182 - Franco-polinésia',
        'Polonesa': '183 - Polonesa',
        'Portorriquenha': '184 - Portorriquenha',
        'Portuguesa': '185 - Portuguesa',
        'Catarense': '186 - Catarense',
        'Queniano': '187 - Queniano',
        'Quirguiz': '188 - Quirguiz',
        'Centro-africana': '189 - Centro-africana',
        'Dominicana': '190 - Dominicana',
        'Tcheco': '191 - Tcheco',
        'Romena': '192 - Romena',
        'Ruandesa': '193 - Ruandesa',
        'Russa': '194 - Russa',
        'Saariano': '195 - Saariano',
        'Pedro-miquelonense': '196 - Pedro-miquelonense',
        'Samoana': '197 - Samoana',
        'Samoano': '198 - Samoano',
        'Samarinês': '199 - Samarinês',
        'Helenense': '200 - Helenense',
        'Santa-lucense': '201 - Santa-lucense',
        'São-bartolomeense': '202 - São-bartolomeense',
        'São-cristovense': '203 - São-cristovense',
        'São-martinhense': '204 - São-martinhense',
        'São-tomense': '205 - São-tomense',
        'Sao-vicentino': '206 - Sao-vicentino',
        'Senegalesa': '207 - Senegalesa',
        'Leonesa': '208 - Leonesa',
        'Sérvia': '209 - Sérvia',
        'Síria': '210 - Síria',
        'Somali': '211 - Somali',
        'Cingalesa': '212 - Cingalesa',
        'Suazi': '213 - Suazi',
        'Sudanesa': '214 - Sudanesa',
        'Sul-sudanês': '215 - Sul-sudanês',
        'Sueca': '216 - Sueca',
        'Suíça': '217 - Suíça',
        'Surinamesa': '218 - Surinamesa',
        'Svalbardense': '219 - Svalbardense',
        'Tadjique': '220 - Tadjique',
        'Tailandesa': '221 - Tailandesa',
        'Taiwanês': '222 - Taiwanês',
        'Tanzaniana': '223 - Tanzaniana',
        'Do Território Britânico do Oceano Ándico': '224 - Do Território Britânico do Oceano Ándico',
        'Do Territórios do Sul da França': '225 - Do Territórios do Sul da França',
        'Timorense': '226 - Timorense',
        'Togolesa': '227 - Togolesa',
        'Tonganesa': '228 - Tonganesa',
        'Trinitário-tobagense': '229 - Trinitário-tobagense',
        'Tristanita': '230 - Tristanita',
        'Tunisiana': '231 - Tunisiana',
        'Turcomana': '232 - Turcomana',
        'Turca': '233 - Turca',
        'Tuvaluana': '234 - Tuvaluana',
        'Ucraniana': '235 - Ucraniana',
        'Ugandense': '236 - Ugandense',
        'Uruguaia': '237 - Uruguaia',
        'Uzbeque': '238 - Uzbeque',
        'Vanuatuense': '239 - Vanuatuense',
        'Vaticano': '240 - Vaticano',
        'Venezuelana': '241 - Venezuelana',
        'Vietnamita': '242 - Vietnamita',
        'Wallis-futunense': '243 - Wallis-futunense',
        'Zambiana': '244 - Zambiana',
        'Zimbabuana': '245 - Zimbabuana'
    },
    tiposCns: {
        '112482': '112482 - 2\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
        '111450': '111450 - 1\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
        '113282': '113282 - 3\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
        '113498': '113498 - 4\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
        '113571': '113571 - 5\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
        '142935': '142935 - 6\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
        '124594': '124594 - 7\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
        '113746': '113746 - 8\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
        '113779': '113779 - 9\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
        '111138': '111138 - 10\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
        '111179': '111179 - 11\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
        '111187': '111187 - 12\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
        '111195': '111195 - 13\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
        '111211': '111211 - 14\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
        '111252': '111252 - 15\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
        '111278': '111278 - 16\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
        '111294': '111294 - 17\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
        '111328': '111328 - 18\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP'
    },
    tiposClassificacao: {
        'Proprietário': '0 - Proprietário',
        'Adquirente': '1 - Adquirente',
        'Transmitente': '2 - Transmitente',
        'Devedor': '3 - Devedor',
        'Credor': '4 - Credor',
        'Anuente': '5 - Anuente',
        'Interveniente': '6 - Interveniente',
        'Fiador': '7 - Fiador',
        'Avalista': '8 - Avalista',
        'Requerente': '9 - Requerente',
        'Autor': '10 - Autor',
        'Réu': '11 - Réu',
        'Exequente': '12 - Exequente',
        'Executado': '13 - Executado',
        'Instituição de condomínio': '14 - Instituição de condomínio',
        'Convenção de Condomínio': '15 - Convenção de Condomínio',
        'Especificação de Condomínio': '16 - Especificação de Condomínio',
        'Retificação de instituição': '17 - Retificação de instituição',
        'Retificação de convenção': '18 - Retificação de convenção',
        'Retificação de Especificação': '19 - Retificação de Especificação',
        'Cônjuge': '20 - Cônjuge',
        'Convivente': '21 - Convivente',
        'Endossatário': '22 - Endossatário',
        'Endossante': '23 - Endossante',
        'Outorgante': '24 - Outorgante',
        'Outorgado': '25 - Outorgado',
        'Emissor': '26 - Emissor',
        'Emissora': '27 - Emissora',
        'Agente fiduciário': '28 - Agente fiduciário',
        'Interveniente garantidor': '29 - Interveniente garantidor',
        'Garantidor': '30 - Garantidor',
        'Cedente': '31 - Cedente',
        'Cessionário': '32 - Cessionário',
        'Promitente vendedor': '33 - Promitente vendedor',
        'Promitente comprador': '34 - Promitente comprador',
        'Expropriante': '35 - Expropriante',
        'Expropriado': '36 - Expropriado',
        'Interessado': '37 - Interessado',
        'Nu-proprietário': '38 - Nu-proprietário',
        'Usufrutuário': '39 - Usufrutuário',
        'Incorporadora': '40 - Incorporadora',
        'Incorporada': '41 - Incorporada',
        'Instituição Custodiante': '42 - Instituição Custodiante',
        'Agente de Garantia': '43 - Agente de Garantia',
        'Agente de Cobrança': '44 - Agente de Cobrança',
        'Órgão emissor': '45 - Órgão emissor',
        'Inventariado': '46 - Inventariado',
        'Meeiro': '47 - Meeiro',
        'Herdeiro': '48 - Herdeiro',
        'Doador': '49 - Doador',
        'Donatário': '50 - Donatário',
        'Renunciante': '51 - Renunciante',
        'Locador': '52 - Locador',
        'Locatário': '53 - Locatário',
        'Causionante': '54 - Causionante',
        'Instituição Financeira': '55 - Instituição Financeira',
        'Representante Legal': '56 - Representante Legal',
        'Depositário': '57 - Depositário'
    },
    tiposEstadosCivil: {
        '1': '1 - Casada',
        '2': '2 - Casado',
        '3': '3 - Divorciada',
        '4': '4 - Divorciado',
        '5': '5 - Espólio',
        '6': '6 - Separada',
        '7': '7 - Separada judicialmente',
        '8': '8 - Separado',
        '9': '9 - Separado judicialmente',
        '10': '10 - Solteira',
        '11': '11 - Solteira, maior',
        '12': '12 - Solteira, menor impúbere',
        '13': '13 - Solteira, menor púbere',
        '14': '14 - Solteiro',
        '15': '15 - Solteiro, maior',
        '16': '16 - Solteiro, menor impúbere',
        '17': '17 - Solteiro, menor púbere',
        '18': '18 - Viúva',
        '19': '19 - Viúvo',
        '20': '20 - Menor emancipado',
        '21': '21 - Outros',
        '22': '22 - Desquitado',
        '23': '23 - Desquitada'
    },
    tiposEstadosCivilOposto: {
        '1': '2',
        '2': '1',
        '3': '4',
        '4': '3',
        '5': '5',
        '6': '8',
        '7': '9',
        '8': '6',
        '9': '7',
        '10': '14',
        '11': '15',
        '12': '16',
        '13': '17',
        '14': '10',
        '15': '11',
        '16': '12',
        '17': '13',
        '18': '19',
        '19': '18',
        '20': '20',
        '21': '21',
        '22': '23',
        '23': '22'
    },
    tiposEstados: {
        '11': '11 - RO',
        '12': '12 - AC',
        '13': '13 - AM',
        '14': '14 - RR',
        '15': '15 - PA',
        '16': '16 - AP',
        '17': '17 - TO',
        '21': '21 - MA',
        '22': '22 - PI',
        '23': '23 - CE',
        '24': '24 - RN',
        '25': '25 - PB',
        '26': '26 - PE',
        '27': '27 - AL',
        '28': '28 - SE',
        '29': '29 - BA',
        '31': '31 - MG',
        '32': '32 - ES',
        '33': '33 - RJ',
        '35': '35 - SP',
        '41': '41 - PR',
        '42': '42 - SC',
        '43': '43 - RS',
        '50': '50 - MS',
        '51': '51 - MT',
        '52': '52 - GO',
        '53': '53 - DF'
    },
    tiposRegimeBens: {
        '1': '1 - Comunhão de bens',
        '2': '2 - Comunhão de bens vigente na Venezuela',
        '3': '3 - Comunhão parcial de bens',
        '4': '4 - Comunhão parcial de bens às Leis da Noruega',
        '5': '5 - Comunhão parcial de bens, à Lei Suiça',
        '6': '6 - Comunhão parcial de bens, antes da vigência da Lei 6.515/77',
        '7': '7 - Comunhão parcial de bens, na vigência da Lei 6.515/77',
        '8': '8 - Comunhão universal de bens',
        '9': '9 - Comunhão universal de bens, antes da vigência da Lei 6.515/77',
        '10': '10 - Comunhão universal de bens, às Leis de Ângola',
        '11': '11 - Comunhão universal de bens, às Leis italianas',
        '12': '12 - Comunhão universal de bens, na vigência da Lei 6.515/77',
        '13': '13 - Conforme a lei vigente em Israel',
        '14': '14 - Leis da Alemanha',
        '15': '15 - Leis da Argentina',
        '16': '16 - Leis da Austrália',
        '17': '17 - Leis da Bolívia',
        '18': '18 - Leis da China',
        '19': '19 - Leis da Colômbia',
        '20': '20 - Leis da Costa do Marfim',
        '21': '21 - Leis da Costa Rica',
        '22': '22 - Leis da Dinamarca',
        '23': '23 - Leis da Espanha',
        '24': '24 - Leis da Finlândia',
        '25': '25 - Leis da França',
        '26': '26 - Leis da Guatemala',
        '27': '27 - Leis da Holanda',
        '28': '28 - Leis da Inglaterra',
        '29': '29 - Leis da Itália',
        '30': '30 - Leis da Islândia',
        '31': '31 - Leis da Jordânia',
        '32': '32 - Leis da Polonia',
        '33': '33 - Leis da República da Coréia',
        '34': '34 - Leis da Suíça',
        '35': '35 - Leis de Angola',
        '36': '36 - Leis de Cuba',
        '37': '37 - Leis de Moscou',
        '38': '38 - Leis de Taiwan',
        '39': '39 - Leis do Canadá',
        '40': '40 - Leis do Japão',
        '41': '41 - Leis do Líbano',
        '42': '42 - Leis do Paraguai',
        '43': '43 - Leis do Uruguai',
        '44': '44 - Leis dos Estados Unidos',
        '45': '45 - Leis Egípcias',
        '46': '46 - Leis Portuguesas',
        '47': '47 - Participação final nos aquestos',
        '48': '48 - Regime de bens conforme as Leis americanas',
        '49': '49 - Regime vigente no Chile',
        '50': '50 - Separação de bens',
        '51': '51 - Separação de bens conforme as Leis da Áustria',
        '52': '52 - Separação de bens, antes da vigência da Lei 6.515/77',
        '53': '53 - Separação de bens, na vigência da Lei 6.515/77',
        '54': '54 - Separação obrigatória de bens',
        '55': '55 - Separação parcial, antes da vigência da Lei 6.515/77',
        '56': '56 - Separação parcial, na vigência da Lei 6.515/77',
        '57': '57 - Separação total de bens, na vigência da Lei 6.515/77'
    },
    tiposAto: {
        '15': '15 - Adjudicação',
        '41': '41 - Arrematação em Hasta Pública',
        '37': '37 - Cessão de Direitos',
        '11': '11 - Compra e Venda',
        '19': '19 - Dação em Pagamento',
        '25': '25 - Desapropriação',
        '43': '43 - Dissolução de Sociedade',
        '21': '21 - Distrato de Negócio',
        '53': '53 - Doação',
        '55': '55 - Doação em adiantamento da legítima',
        '27': '27 - Herança, Legado ou Meação (adjudicação)',
        '45': '45 - Incorporação e loteamento',
        '47': '47 - Integralização/Subscrição de capital',
        '49': '49 - Partilha amigável ou litigiosa',
        '13': '13 - Permuta',
        '31': '31 - Procuração em Causa Própria',
        '35': '35 - Promessa de Cessão de Direitos',
        '33': '33 - Promessa de Compra e Venda',
        '51': '51 - Retorno de Capital próprio',
        '99': '99 - Outros',
        '101': '101 - Usucapião',
        '102': '102 - Alienação Fiduciária',
        '103': '103 - Consolidação da Propriedade',
        '104': '104 - Hipoteca',
        '201': '201 - Encerramento da matrícula pelo cartório',
        '202': '202 - Cancelamento da matrícula administrativamente',
        '203': '203 - Cancelamento da matrícula judicialmente',
        '204': '204 - Requalificação da matrícula (Prov. 13 CGJ/PA)',
        '205': '205 - Abertura da matrícula',
        '206': '206 - Averbação',
        '207': '207 - Cancelamento de ônus',
        '208': '208 - Compromisso de Compra e Venda',
        '209': '209 - Sub-rogação',
        '210': '210 - Transporte de ônus',
        '211': '211 - Formal de Partilha',
        '212': '212 - Construção, Reforma ou Ampliação',
        '213': '213 - Demolição',
        '214': '214 - Desmembramento',
        '215': '215 - Atualização de Dados do Imóvel',
        '216': '216 - Retificação de Dados do Imóvel',
        '217': '217 - Ex-Officio',
        '218': '218 - Casamento',
        '219': '219 - Divórcio',
        '220': '220 - Separação',
        '221': '221 - União Estável ',
        '222': '222 - Aditivo',
        '223': '223 - Ratificação',
        '224': '224 - Retificação ',
        '225': '225 - Óbito',
        '226': '226 - Confrontações',
        '227': '227 - Cláusula',
        '228': '228 - Arresto',
        '229': '229 - Assunção de Dívida',
        '230': '230 - Constituição de Sociedade',
        '231': '231 - Transferência de bens',
        '232': '232 - Cisão',
        '233': '233 - Penhora',
        '234': '234 - Usufruto',
        '235': '235 - Endosso ',
        '236': '236 - Locação',
        '237': '237 - Caução',
        '238': '238 - Indisponibilidade de bens',
        '239': '239 - Cédula de Crédito',
        '240': '240 - Instituição de Condomínio',
        '241': '241 - Instituição de Bem de Família',
        '242': '242 - Cessão de Crédito',
        '243': '243 - Bloqueio de Matrícula ',
        '244': '244 - Ação de Execução',
        '245': '245 - Pacto Comissório ',
        '246': '246 - Desdobro',
        '247': '247 - Arrolamento de bens',
        '248': '248 - Divisão ',
        '249': '249 - Incorporação de Condomínio',
        '250': '250 - Sequestro',
        '251': '251 - Servidão',
        '252': '252 - Penhor',
        '253': '253 - Alvará de Execução',
        '254': '254 - Patrimônio de Afetação ',
        '255': '255 - Unificação',
        '256': '256 - Domínio',
        '257': '257 - Nota Promissória ',
        '258': '258 - Transporte de atos',
        '259': '259 - Incomunicabilidade (Consórcio - p.5º do art. 5º da lei 11.795/2008)',
        '260': '260 - Suspensão do procedimento de execução',
        '261': '261 - Transformação de Natureza Jurídica',
        '262': '262 - Endosso para efeito de caução',
        '263': '263 - Direito de Uso',
        '264': '264 - Auto de Arrecadação',
        '265': '265 - Cláusula de Reversão',
        '266': '266 - Incorporação de Sociedade',
        '267': '267 - Conferência de bens',
        '268': '268 - Existência de Ação',
        '269': '269 - Compra e venda do Usufruto',
        '270': '270 - Termo de Restauração',
        '271': '271 - Alteração do Estado Civil',
        '272': '272 - Retificação do Estado Civil ',
        '273': '273 - Naturalização',
        '274': '274 - Emancipação',
        '275': '275 - Inclusão de Documentação',
        '276': '276 - Retificação de Documentação',
        '277': '277 - Alteração de Razão Social',
        '278': '278 - Atribuição',
        '279': '279 - Cancelamento parcial de ônus',
        '280': '280 - Compra e venda da nua-propriedade',
        '281': '281 - Emissão',
        '282': '282 - Fusão',
        '283': '283 - Tombamento',
        '284': '284 - Cadastro/Cadastro Atual',
        '285': '285 - Decesso',
        '286': '286 - Alteração do Estado Civil - Divórcio',
        '287': '287 - Alteração do Estado Civil - Casamento',
        '288': '288 - Divórcio com Partilha de Bens',
        '289': '289 - Partilha de Bens',
        '290': '290 - Transmissão de Domínio',
        '291': '291 - Reserva de Usufruto (Ônus)',
        '292': '292 - Renúncia de Usufruto (Ônus)',
        '293': '293 - Cancelamento de Caução (Ônus)',
        '294': '294 - Mudança de Denominação Social/Alteração da Razão Social',
        '295': '295 - Instituição de Usufruto',
        '296': '296 - Proporção Aquisitiva',
        '297': '297 - Proporção Aquisitiva Atual',
        '298': '298 - Levantamento/cancelamento de Indisponibilidade',
        '299': '299 - Decisão Judicial',
        '300': '300 - Cancelamento da Locação',
        '301': '301 - Qualificação Subjetiva',
        '302': '302 - Condição Resolutiva'
    },
    tiposNatureza: {
        '1': '1 - Convenção de Condomínio',
        '2': '2 - Instituição de Condomínio',
        '3': '3 - Especificação de Condomínio',
        '4': '4 - Bem de Família',
        '5': '5 - Pacto Antenupcial',
        '6': '6 - União Estável',
        '7': '7 - Cédula de Crédito bancário',
        '8': '8 - Cédula de Crédito Comercial',
        '9': '9 - Cédula de Crédito Industrial',
        '10': '10 - Cédula Rural Hipotecária',
        '11': '11 - Cédula Rural Pignoratícia e Hipotecária',
        '12': '12 - Cédula Rural Pignoratícia',
        '13': '13 - Outros'
    }
}

export default listaControlada;