<template>
    <div class="control">
        <label class="label" v-html="label"></label>
        <input 
            class="input" 
            :class="{ 'is-danger': (!preenchido && !preenchidoInput) || !preenchidoDocumento, 'tabindex': label == 'Cidade' || label == 'UF' }"
            @change="atualizarPreenchimento($event.target.value)"
            @keyup="onKeyUp($event.target.value)"
            @keydown.tab="tabCampo(tab)"
            @keydown.enter="keyEnter()"
            @focus="onFocus()"
            @blur="onBlur($event.target.value)"
            :type="tipo ?? 'text'" 
            :tabindex="tabindex ?? false" 
            v-mask="mascara" 
            v-money="mascaraMonetaria ?? false" 
            :disabled="disabled" 
            :placeholder="placeholder" 
            :data-campo="indice" 
            ref="input"
            :value="value" 
            @input="$emit('input', $event.target.value)"
        >        
                                                
        <Loader v-if="loading == true"
            class="loader-input"
        />
    </div>
</template>

<script>
import { cpf, cnpj } from 'cpf-cnpj-validator'
import moment from "moment"

export default {
    name: "InputText",
    props: ['label', 'value', 'placeholder', 'enter', 'focus', 'blur', 'buscarCep', 'buscarDocumentoBeneficiario', 'buscarCepPorEndereco', 'buscarTabelionatos', 'aba', 'conjuge', 'buscarDocumento', 'buscarDocumentoConjuge', 'adicionarConjuge', 'tipo', 'mascara', 'mascaraMonetaria', 'disabled', 'preenchido', 'tabindex', 'tab', 'tabUltimoCampo', 'inconsistencia', 'indice', 'indiceAto', 'verificarRegistro', 'loading', 'onkeyup'],
    data() {
        return {
            preenchidoInput: false,
            preenchidoDocumento: true,
            preenchidoCep: true
        }
    },
    methods: {
        /**
         * Função para verificar o preenchimento do input
         */
        atualizarPreenchimento(sValor) {
            this.preenchidoInput = true

            if(sValor == ''){
                this.preenchidoInput = false
            }else{
                if(this.mascara == '###.###.###-##' || this.mascara == '##.###.###/####-##'){
                    if(sValor.length <= 14){
                        this.preenchidoDocumento = cpf.isValid(sValor)
                    }else if(sValor.length <= 18){
                        this.preenchidoDocumento = cnpj.isValid(sValor)
                    }
                }else{
                    this.preenchidoDocumento = true
                }

                if(this.mascara == '#####-###'){
                    this.preenchidoCep = /^[0-9]{8}$/.test(sValor.replace('-', ''))
                }

                if(this.adicionarConjuge){
                    this.adicionarConjuge()
                }
            }   

            if(this.label == 'Número (Matrícula, L3, etc.)' && this.verificarRegistro !== undefined){
                this.verificarRegistro()
            }

            if(!this.preenchidoDocumento){
                toastAlert('CPF/CNPJ inválido')
                
                if(this.inconsistencia){
                    this.inconsistencia(this.aba ?? 'ato', 1)
                }
            }else if(!this.preenchidoCep){
                toastAlert('CEP inválido')
            }else{
                if(this.inconsistencia){
                    this.inconsistencia(this.aba ?? 'ato', 2)
                }
            }
            
            if(this.tipo == 'date'){ 
                if(sValor != ''){
                    if(!moment(sValor, 'YYYY-MM-DD', true).isValid()){
                        toastAlert('Data inválida')
                        this.preenchidoDocumento = false
                    }
                }
            }
        },
        /**
         * Função para chamar a função de tab do componente pai
         */
        tabCampo(sValor) {
            if(this.blur !== undefined){
                this.blur(this.indice, true)
            }

            if(this.tabUltimoCampo !== undefined){
                this.tabUltimoCampo(sValor)
            }
        },
        /**
         * Função para chamar a função ao clicar no enter
         */
        keyEnter() {
            if(this.enter !== undefined){
                this.enter(this.indice)
            }
        },
        /**
         * Função para chamar a função no focus
         */
        onFocus() {
            if(this.focus !== undefined){
                this.focus(this.indice)
            }
        },
        /**
         * Função para chamar a função no blur
         */
        onBlur(sValor) {
            if(this.blur !== undefined){
                this.blur(this.indice)
            }
        },
        /**
         * Função para chamar a função ao clicar na tecla
         */
        onKeyUp(sValor){
            if(this.buscarCep){
                this.buscarCep(sValor, this.indice)
            }

            if(this.buscarCepPorEndereco){
                this.buscarCepPorEndereco(sValor, this.indice, this.aba)
            }

            if(this.buscarTabelionatos){
                this.buscarTabelionatos(sValor)
            }

            if(this.buscarDocumento){
                this.buscarDocumento(sValor, this.aba, this.conjuge ?? false)
            }

            if(this.buscarDocumentoConjuge){
                this.buscarDocumentoConjuge(sValor, this.aba, this.conjuge ?? false)
            }

            if(this.buscarDocumentoBeneficiario){
                this.buscarDocumentoBeneficiario(sValor, this.indice)
            }

            if(this.onkeyup){
                this.onkeyup(sValor)
            }
        }
    }
}
</script>

<style>
    .loader-input{
        position: absolute;
        transform: scale(0.3);
        top: 42px;
        right: -10px; 
        height: 0vh!important;
    }

    .listaAtos > div > label, .listaRegistros > div > label, .listaEnderecos > div > label {
        color: #1d2c4d;
        font-size: 18px!important;
    }

    .listaPartes > div > label {
        color: #276cda;
        font-size: 18px!important;
    }
</style>