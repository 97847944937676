<template>
    <div style="width: 100%; color: #fff; background: #d70014; text-align: center;font-size: 14px;" v-if="env != 'producao' && env != 'producao_local'">
        <div style="padding: 10px;">
            <b>Atenção! Este sistema está sendo executado na área de desenvolvimento apenas para testes.</b>
            As alterações realizadas não terão efeito no sistema publicado.
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MarcaDev',
        data() {
            return {
                env: process.env.VUE_APP_ENV
            }
        },        
    }
</script>

<style>
</style>