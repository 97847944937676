<template>
    <Loader v-if="carregando" />
    <div class="login" v-else>
        <div class="login-imagem">
            <img class="amplify-image" alt="Logo" src="/logo.png" style="width: 50%; margin: auto;" />
        </div>
        <form @submit.prevent="entrar">
            <div class="login-acesso">
                <InputText
                    :label="`Nome do usuário`" 
                    v-model="login.usuario"
                    :preenchido="true"
                />

                <InputText
                    :label="`Senha`" 
                    :tipo="`password`"
                    v-model="login.senha"
                    :preenchido="true"
                />
                <br>
                <Button
                    :classes="`is-2risp`" 
                    :titulo="`Entrar`" 
                />
            </div>
        </form>
        <div class="login-footer">
            <p> © Todos os direitos reservados </p>
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    import qs from "qs"

    export default {
        name: 'Login',
        data() {
            return {
                login: {
                    usuario: '',
                    senha: ''
                },
                carregando: false
            }
        },
        mounted() {
            if(sessionStorage.getItem('access_token')){
                this.carregando = true
                window.location.href = '/indicadores'
            }
        },
        methods: {
            /**
             * Função para realizar o login na aplicação
             */
            entrar() {
                this.carregando = true

                axios.request({
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${process.env.VUE_APP_ENDPOINT_AUTH}/v1/login`,
                    headers: { 
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: qs.stringify({
                        'grant_type': 'password',
                        'usuario': this.login.usuario,
                        'senha': this.login.senha, 
                    })
                })
                .then((response) => {
                    sessionStorage.setItem('refresh_token', response.data.refresh_token)
                    sessionStorage.setItem('access_token', response.data.access_token)
                    sessionStorage.setItem('token_type', response.data.token_type)

                    window.location.href = '/indicadores'
                })
                .catch((error) => {
                    this.carregando = false
                    toastAlert('Usuário ou senha inválidos')
                });
            }
        }
    }
</script>

<style>
    body{
        background: #eee;
        min-height: 100vh;
    }

    .login {
        margin: 0 auto;
        width: 30rem;
    }

    .login-imagem {
        padding: 1.5rem;
        text-align: center;
    }

    .login-acesso {
        background-color: hsl(0, 0%, 100%);
        box-shadow: 0px 2px 6px hsla(210, 50%, 10%, .15);
        border-color: hsl(210, 10%, 58%);
        border-width: 1px;
        border-style: solid;
        padding: 1rem 2rem 2rem 2rem;
        display: flex;
        flex-direction: column;
    }

    .login-acesso > button {
        margin-top: 1rem;
    }

    .login-footer{
        padding-top: 2rem;
        padding-bottom: 2rem;
        text-align: center;
    }

    .login-footer > p {
        color: hsl(210, 10%, 40%);
    }

    .is-2risp{
        background-color: #b96082;
        color: #fff;
    }

    .is-2risp:hover{
        background-color: #9f4869;
        color: #fff;
    }

    @media screen and (min-height: 550px){
        .login-footer{
            padding: 1.5rem;
            text-align: center;
            position: absolute;
            bottom: 10px;
            width: 99vw;
            left: 0vh;
        }
    }
</style>