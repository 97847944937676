<template>
    <div v-if="deveMostrar">
        <p>{{ titulo }}: <b>{{ valor }}</b></p>
    </div>
</template>
  
<script>
export default {
    props: ['condicao', 'titulo', 'valor'],
    computed: {
        deveMostrar() {
            return this.condicao && this.valor !== '';
        },
    },
};
</script>