import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMask from 'v-mask'
import VMoney from 'v-money';
import Indicadores from './components/indicadores/Indicadores.vue'
import Pesquisa from './components/indicadores/Pesquisa.vue'
import Documentos from './components/indicadores/Documentos.vue'
import Usuarios from './components/usuarios/Usuarios.vue'
import Enderecos from './components/indicadores/Enderecos.vue'
import Exportacao from './components/indicadores/Exportacao.vue'
import Estatisticas from './components/indicadores/Estatisticas.vue'
import ValidarIP from './components/ValidarIP.vue'
import Erro404 from './components/Erro404.vue'
import Button from './components/layout/Button.vue'
import InputText from './components/layout/InputText.vue'
import SwitchButton from './components/layout/SwitchButton.vue'
import InputFiltro from './components/layout/InputFiltro.vue'
import Select from './components/layout/Select.vue'
import Textarea from './components/layout/Textarea.vue'
import SelectDefault from './components/layout/SelectDefault.vue'
import Loader from './components/layout/Loader.vue'
import Pagination from './components/layout/Pagination.vue'
import Login from './components/Login.vue'
import Visualizacao from './components/layout/Visualizacao.vue'
import MarcaDev from './components/layout/MarcaDev.vue'
import Versao from './components/layout/Versao.vue'
import Menu from './components/layout/Menu.vue'
import Sessao from './components/layout/Sessao.vue'

Vue.use(VMoney)
Vue.use(VueMask)
Vue.use(VueRouter)

Vue.config.productionTip = false

Vue.component('Button', Button)
Vue.component('InputText', InputText)
Vue.component('SwitchButton', SwitchButton)
Vue.component('InputFiltro', InputFiltro)
Vue.component('Select', Select)
Vue.component('SelectDefault', SelectDefault)
Vue.component('Textarea', Textarea)
Vue.component('Loader', Loader)
Vue.component('Pagination', Pagination)
Vue.component('Login', Login)
Vue.component('Visualizacao', Visualizacao)
Vue.component('MarcaDev', MarcaDev)
Vue.component('Versao', Versao)
Vue.component('Menu', Menu)
Vue.component('Sessao', Sessao)

const routes = [
    { path: '/', component: ValidarIP },
    { path: '/login', component: Login },
    { path: '/indicadores', component: Indicadores },
    { path: '/indicadores/:edicao', component: Indicadores },
    { path: '/pesquisa', component: Pesquisa },
    { path: '/documentos', component: Documentos },
    { path: '/usuarios', component: Usuarios },
    { path: '/enderecos', component: Enderecos },
    { path: '/exportacao', component: Exportacao },
    { path: '/estatisticas', component: Estatisticas },
    { path: '*', component: Erro404 }
]
  
const router = new VueRouter({
    mode: 'history',
    routes
});
  
new Vue({
    router,
    render: (h) => h('router-view'),
}).$mount('#app')
