<template>
    <div class="barra-superior">
        <div style="display: flex; align-items: center; justify-content: space-between; gap: 2rem;">
            <img class="amplify-image" alt="Logo" src="/logo.png" style="width: 150px; margin-left: 1.5rem" />
            <span style="font-size: 24px; margin: auto">Indicadores</span>
        </div>
        <div style="margin-right: 2rem; display: flex; gap: 1rem">
            <Button
                :classes="`is-blue-steel is-outlined ${ativo == 'indicadores' ? 'is-active' : ''}`" 
                :titulo="`Digitação`" 
                :onclick="navegacao"
                :parametro1="`indicadores`"
                style="width: 7rem; border-color: #ccc;"
            />
            
            <Button
                :classes="`is-blue-steel is-outlined ${ativo == 'pesquisa' ? 'is-active' : ''}`" 
                :titulo="`Pesquisa`" 
                :onclick="navegacao"
                :parametro1="`pesquisa`"
                style="width: 7rem; border-color: #ccc;"
            />
    
            <Button
                :classes="`is-blue-steel is-outlined ${ativo == 'exportacao' ? 'is-active' : ''}`" 
                :titulo="`Exportação`" 
                :onclick="navegacao"
                :parametro1="`exportacao`"
                v-if="autenticacao.admin"
                style="width: 7rem; border-color: #ccc;"
            />
            
            <Button
                :classes="`is-blue-steel is-outlined ${ativo == 'documentos' ? 'is-active' : ''}`" 
                :titulo="`Documentos`"
                :onclick="navegacao"
                :parametro1="`documentos`"
                v-if="autenticacao.admin"
                style="width: 7rem; border-color: #ccc;"
            />
            
            <Button
                :classes="`is-blue-steel is-outlined ${ativo == 'enderecos' ? 'is-active' : ''}`" 
                :titulo="`Endereços`"
                :onclick="navegacao"
                :parametro1="`enderecos`"
                v-if="autenticacao.admin"
                style="width: 7rem; border-color: #ccc;"
            />

            <Button
                :classes="`is-blue-steel is-outlined ${ativo == 'estatisticas' ? 'is-active' : ''}`" 
                :titulo="`Estatísticas`" 
                :onclick="navegacao"
                :parametro1="`estatisticas`"
                v-if="autenticacao.admin"
                style="width: 7rem; border-color: #ccc;"
            />

            <div class="dropdown is-right is-hoverable">
                <div class="dropdown-trigger">
                    <button class="button is-secondary" aria-haspopup="true" aria-controls="dropdown-menu3">
                        <i class="gg-more-vertical-alt"></i>
                    </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu3" role="menu">
                    <div class="dropdown-content">
                        <a class="dropdown-item" @click="navegacao('usuarios')" v-if="autenticacao.admin">
                            Usuários
                        </a>
                        <a class="dropdown-item" @click="sairUsuario()">
                            Sair
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { sair } from '../../services/auth.js'

    export default {
        name: 'Menu',
        props: ['autenticacao', 'ativo'],
        data() {
            return {
                
            }
        },
        methods: {
            navegacao(rota) {
                window.location.href = `/${rota}`
            },
            sairUsuario() {
                sair(this)
            }
        }
    }
</script>

<style>

</style>