<template>
    <div>        
        <Button
            :classes="`is-${contagemRegressivaClass} btn-small refresh-token`" 
            style="margin-right: -45px; width: 16rem;"
            :onclick="refreshToken"
            :titulo="`${contagemRegressiva}`" 
        />
    </div>
</template>

<script>
    import { refreshTokenService, calcularExpiracao } from '../../services/auth.js'

    export default {
        name: 'Sessao',
        props: ['autenticacaoSessao'],
        data() {
            return {
                contagemRegressiva: 0,
                contagemRegressivaClass: 'blue-steel',
                autenticacao: this.autenticacaoSessao,
                expiracaoInterval: null
            }
        },
        methods: {
            async refreshToken() {
                await refreshTokenService(this)
            }
        },
        mounted() {
            /**
             * Realiza a contagem da expiração
             */
            calcularExpiracao(this)
            
            this.expiracaoInterval = setInterval(() => {
                this.contagemRegressiva = calcularExpiracao(this, true)
            }, 1000);
        }
    }
</script>

<style>

</style>