<template>
    <button 
        :class="`button ${classes}`" 
        :disabled="disabled" 
        :tabindex="tabindex ?? false" 
        @click="onClick"
        @keydown.tab="tabCampo(tab)"
    >
        <div class="icon" v-if="icone && titulo == ''">
            <i :class="`gg-${icone}`"></i>
        </div>
        <div v-if="titulo">{{ titulo }}</div>
    </button>
</template>

<script>
    export default {
        name: "Button",
        props: ['classes', 'icone', 'titulo', 'disabled', 'onclick', 'parametro1', 'parametro2', 'parametro3', 'tabindex', 'tab', 'tabUltimoCampo'],
        methods: {  
            /**
             * Função para ser executado quando tiver um clique no botão
             */
            onClick() {
                if(typeof this.onclick === 'function'){
                    this.onclick(this.parametro1, this.parametro2, this.parametro3)
                }
            },     
            /**
             * Função para chamar a função de tab do componente pai
             */
            tabCampo(sValor) {
                if(this.tabUltimoCampo !== undefined){
                    this.tabUltimoCampo(sValor)
                }
            }
        }
    }
</script>

<style>
    .btn-small{
        transform: scale(0.7);
        margin: 0px auto;
        display: flex;
        font-weight: 500;
    }

    .btn-small-alt{
        margin: 0px auto;
        display: flex;
        font-weight: 500;
        height: 1.6rem;
        font-size: 11px;
        margin: 0.25rem;
    }


    .btn-voltar{
        float: right;
        margin-right: 13px!important;
    }

    .button .icon:first-child:last-child{
        margin-left: -15px;
        margin-right: -15px;
    }

    .button.is-warning-alt.is-outlined{
        background-color: transparent;
        border-color: #ff7b6b;
        color: #ff7b6b;
    }

    .button.is-warning-alt.is-outlined:hover, .button.is-warning-alt.is-outlined:focus {
        background-color: #ff7b6b;
        border-color: #ff7b6b;
        color: #fff;
    }

    .button.is-gray{
        background-color: #ddd;
        border-color: #ccc;
        color: #000;
    }

    .button.is-gray.is-outlined{
        background-color: transparent;
        border-color: #ccc;
        color: #000;
    }

    .button.is-gray.is-outlined:hover{
        background-color: #ddd;
        border-color: #ccc;
    }

    .button.is-gray-alt{
        background-color: #EDEDED;
        border-color: #b5b5b5;
        color: #000;
    }

    .button.is-purple{
        background-color: #7c38bc;
        border-color: #7c38bc;
        color: #fff;
    }

    .button.is-purple:hover{
        background-color: #6f32a8;
        border-color: #6f32a8;
    }

    .button.is-yellow{
        background-color: #ffc107;
        border-color: #ffc107;
        color: #212529;
    }

    .button.is-yellow:hover{
        background-color: #e0a800;
        border-color: #e0a800;
    }

    .button.is-blue-steel{
        background-color: #1d2c4d;
        border-color: #1d2c4d;
        color: #fff;
    }

    .button.is-blue-steel.is-outlined{
        background-color: transparent;
        border-color: #1d2c4d;
        color: #1d2c4d;
    }

    .button.is-blue-steel.is-outlined:hover, .button.is-blue-steel.is-outlined:focus, .button.is-blue-steel.is-outlined.is-active {
        background-color: #1d2c4d;
        border-color: #1d2c4d!important;
        color: #fff;
    }
</style>